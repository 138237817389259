import React from 'react'
import '../Styles/CrisWorld.css'

function CrisWorld() {
  return (
    <section id='crisworld' className='flex-center flex__gap-50 background-dark-light padding-100'>
        <div className='half-screen flex-center'>
            <h3 id='title-crisworld'>Descubra o Mundo Envolvente dos Romances Hot de <span>CRIS ZUCA</span></h3>
        </div>
        <div className='half-screen flex-center'>
            <p className='paragraph-default'>Como você se sentiria se descobrisse que tudo o que você acreditou ser verdadeiro não passa de uma mentira? O pior é que as coisas não acontecem por acaso, há um motivo e uma razão que a lógica desconhece. 
            O despertar é a continuação da trilogia Tudo tem um porquê, nele você irá, junto dos personagens, despertar para uma vida antes inimaginável.</p>
        </div>
    </section>
  )
}

export default CrisWorld
