import React from 'react'
import '../Styles/Spoiler.css'

function Spoiler() {
  return (
    <section id="spoiler-section" className='flex-center flex-column flex__gap-100 padding-100'>
        <h1 id='title-spoiler'>Confira alguns <span id='spoiler-bold'>spoilers</span><br/> sobre a <span id='autora-bold'>autora</span></h1>
        <div id='container-videos' className='flex-center flex__gap-50 flex__wrap'>
            <div>
                <iframe src="https://player.vimeo.com/video/968849561?badge=0&autopause=0&player_id=0&app_id=58479" width="240" height="420" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>

            <div>
                <iframe src="https://player.vimeo.com/video/968849561?badge=0&autopause=0&player_id=0&app_id=58479" width="240" height="420" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>

            <div>
                <iframe src="https://player.vimeo.com/video/968849561?badge=0&autopause=0&player_id=0&app_id=58479" width="240" height="420" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
        </div>
    </section>
  )
}

export default Spoiler
